import gql from 'graphql-tag';
import 'react-image-lightbox/style.css';
import Main from '../components/Main';
import Header from '../components/Header';
import Gallery from '../components/Gallery';
import SingleMedia from '../components/SingleMedia';
import { useParams } from 'react-router';

const CACHE_LIST = gql`
    query GetCache($offset: Int!, $limit: Int!) {
        cacheList(offset: $offset, limit: $limit) {
            key
            created
            hash
            filename
            filename_thumb
            mime
            prev
            next
            prev_filename
            next_filename
        }
    }
`;

const CACHE_SINGLE = gql`
    query CacheSingle($key: String!) {
        cacheSingle(key: $key) {
            key
            created
            hash
            filename
            filename_thumb
            mime
            prev
            next
            prev_filename
            next_filename
        }
    }
`;

const CacheList = () => {
    return (
        <>
            <Header />
            <Main>
                <Gallery listQuery={CACHE_LIST} singleQuery={CACHE_SINGLE} filePrefix="/cache/" />
            </Main>
        </>
    );
};

export default CacheList;
