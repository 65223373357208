import Header from '../components/Header'
import Main from '../components/Main'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import Gallery from '../components/Gallery';
import withAuth from '../components/withAuth';
import { format } from 'date-fns'


const MAIL_LIST = gql`
    query MailList($offset: Int!, $limit: Int!) {
        mailList(offset: $offset, limit: $limit) {
            key
            filename
            filename_thumb
            original_filename
            subject
            sender
            created
            prev
            next
            prev_filename
            next_filename
        }
    }
`;

const MAIL_SINGLE = gql`
    query MailSingle($key: String!) {
        mailSingle(key: $key) {
            key
            filename
            filename_thumb
            original_filename
            created
            prev
            subject
            sender
            created
            next
            prev_filename
            next_filename
        }
    }
`;


const Mail = () => {
    const getCaption = (item: any) => {
        const d = new Date(parseInt(item.created, 10));
        return item.subject + ' from ' + item.sender + ' (' + format(d, "yyyy-MM-dd HH:mm:SS") + ')';
    };
    const MainWithAuth = withAuth(Main);
    return (
        <>
            <Header />
            <MainWithAuth>
                <Gallery listQuery={MAIL_LIST} singleQuery={MAIL_SINGLE} getCaption={getCaption} filePrefix="/mail/" />
            </MainWithAuth>
        </>
    );
}

export default Mail;
