import * as React from 'react';
import { useMutation, gql } from '@apollo/client';
import styled from 'styled-components';

const LOGIN = gql`
    mutation($password: String) {
        login(password: $password) {
            authed
        }
    }
`;

const LoginForm = styled.div`
    text-align: center;
`;

const Login = (props: any) => {
    const { loginCallback } = props;
    const [password, setPassword] = React.useState('');
    const [mutate, { data, loading, error }] = useMutation(LOGIN);
    const onSubmit = async (e: any) => {
        e.preventDefault();
        const result = await mutate({
            variables: {
                password
            }
        });
        console.log('calling', result.data);
        loginCallback(result.data.login.authed);
    }
    if (loading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error: ${JSON.stringify(error, null, 2)}</div>;
    }
    return (
        <LoginForm>
            <form onSubmit={onSubmit} >
                <input type="password" value={password} onChange={(e: any) => setPassword(e.target.value)} />
                <input type="submit" value="login" />
            </form >
        </LoginForm>
    );
};


export default Login;
