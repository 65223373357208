import Header from '../components/Header'
import Main from '../components/Main'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import Gallery from '../components/Gallery';
import withAuth from '../components/withAuth';
import { format } from 'date-fns'

const SINGLE_UPLOAD = gql`
    mutation($file: Upload!) {
        singleUpload(file: $file) {
            filename
            mimetype
            encoding
        }
    }
`;

const UPLOAD_LIST = gql`
    query UploadList($offset: Int!, $limit: Int!) {
        uploadList(offset: $offset, limit: $limit) {
            key
            filename
            filename_thumb
            original_filename
            created
            prev
            next
            prev_filename
            next_filename
        }
    }
`;

const UPLOAD_SINGLE = gql`
    query UploadSingle($key: String!) {
        uploadSingle(key: $key) {
            key
            filename
            filename_thumb
            original_filename
            created
            prev
            next
            prev_filename
            next_filename
        }
    }
`;


const Upload = () => {
    const [mutate, { data, loading, error }] = useMutation(SINGLE_UPLOAD);
    const onChange = async ({
        target: {
            validity,
            files: [file]
        }
    }: any) => {
        console.log('file', file);
        if (validity.valid) {
            try {
                await mutate({ variables: { file } });
                window.location.reload();
            } catch (e) {
                console.log('e', e);
            }
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Upload failed: {error.message || 'Unknown error'}</div>;
    const getCaption = (item: any) => {
        const d = new Date(parseInt(item.created, 10));
        return item.original_filename + ' (' + format(d, "yyyy-MM-dd HH:mm:SS") + ')';
    };
    const MainWithAuth = withAuth(Main);
    return (
        <>
            <Header />
            <MainWithAuth>
                <fieldset style={{ display: 'inline-block' }}>
                    <legend>Upload file</legend>
                    <input type="file" accept="video/*,image/*" required onChange={onChange} />
                </fieldset>
                <Gallery listQuery={UPLOAD_LIST} singleQuery={UPLOAD_SINGLE} getCaption={getCaption} filePrefix="/upload/" />
            </MainWithAuth>
        </>
    );
}

export default Upload;
