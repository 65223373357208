import * as React from "react";
import * as ReactDOM from "react-dom";
import { ApolloLink, ApolloProvider, ApolloClient, InMemoryCache, from } from '@apollo/client';
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import CacheList from './pages/CacheList';
import Upload from './pages/Upload';
import Mail from './pages/Mail';
import { createUploadLink } from 'apollo-upload-client';

const cache: InMemoryCache = new InMemoryCache({});

const uploadLink = createUploadLink({
    uri: '/graphql',
});
const client = new ApolloClient({
    link: (uploadLink as unknown) as ApolloLink,
    cache,
});

const App = () => (
    <ApolloProvider client={client}>
        <Router>
            <Switch>
                <Route exact path="/">
                    <CacheList />
                </Route>
                <Route exact path="/cache">
                    <CacheList />
                </Route>
                <Route exact path="/cache/single/:key">
                    <CacheList />
                </Route>
                <Route exact path="/upload">
                    <Upload />
                </Route>
                <Route exact path="/upload/single/:key">
                    <Upload />
                </Route>
                <Route exact path="/mail">
                    <Mail />
                </Route>
                <Route exact path="/mail/single/:key">
                    <Mail />
                </Route>
            </Switch>
        </Router>
    </ApolloProvider>
);

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
