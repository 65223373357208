import { Link } from "react-router-dom";

const Header = () => {
    return (
        <>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <img src="/banner.png" alt="banner" />
            </div>
            <div style={{
                textAlign: 'center'
            }}>
                <Link to="/cache">image cache</Link>
                &nbsp;|&nbsp;
                <Link to="/upload">upload</Link>
                &nbsp;|&nbsp;
                <Link to="/mail">mail</Link>
            </div>
            <br />
        </>
    );
}

export default Header;
