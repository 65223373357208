import * as React from 'react';
import Login from './Login';
import { useQuery, gql } from '@apollo/client';

const IS_AUTHED = gql`
    query IsAuthed {
        isAuthed {
            authed
        }
    }
`;

const withAuth = (Component: any) => {
    return function WithauthComponent({ ...props }): JSX.Element {
        const [loggedIn, setLoggedIn] = React.useState(false);
        const { data, loading, error, refetch } = useQuery(IS_AUTHED);
        console.log(loggedIn, data);
        const cb = (auth: boolean) => {
            console.log('setLoggedIn', auth);
            setLoggedIn(auth);
            // invalidate cache, blaherg...
            refetch();
        }
        if (loading) {
            return <div>Loading...</div>;
        }
        if (error) {
            return <div>Error: ${JSON.stringify(error, null, 2)}</div>;
        }
        if (data.isAuthed.authed || loggedIn) {
            return <Component {...props} />;
        } else {
            return <Login loginCallback={cb} />;
        }
    };
};

export default withAuth;
