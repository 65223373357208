import * as React from "react";
import { useQuery, useLazyQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 200px);
    width: 90%;
    margin: 0 auto;
    backgroundColor: #000;
    text-align: center;
    max-width: 1280px;
`;


const Cell = styled.div`
    background-color: #333;
`;

const Thumbnail = styled.img`
    position: relative;
    top: 50%,
    transform: translateY(-50%);
`;

export type getCaption = (item: any) => string;

export interface GalleryProps {
    listQuery: DocumentNode;
    singleQuery: DocumentNode;
    filePrefix: string;
    getCaption?: getCaption;
};

const Gallery = (props: GalleryProps) => {
    console.log('rendering');
    const PER_PAGE = 36;
    const history = useHistory();
    const params: any = useParams();
    const { key } = params;
    const [hasMoreToLoad, setHasMoreToLoad] = React.useState(true);
    const [offset, setOffset] = React.useState(0);
    const [items, setItems]: [any[], any] = React.useState([]);
    const [getList, { loading, error }] = useLazyQuery(props.listQuery, {
        onCompleted: (data: any) => {
            const newItems = data[Object.keys(data)[0]];
            if (newItems.length < PER_PAGE) {
                setHasMoreToLoad(false);
            }
            console.log('onCompleted');
            setItems([...items, ...newItems])
        }
    });
    React.useEffect(() => {
        console.log('getList', key, loading, items.length)
        if (!key && !loading) {
            console.log('calling');
            getList({
                variables: {
                    offset: offset,
                    limit: PER_PAGE,
                },
            });
        }
    }, [offset, key]);
    const [getSingle, { data: singleData, loading: singleLoading, error: singleError, called }] = useLazyQuery(props.singleQuery, {
        onCompleted: (data: any) => {
            const newItems = data[Object.keys(data)[0]];
            /* if (newItems.length < PER_PAGE) {
             *     // at most one extra request..
             *     setHasMoreToLoad(false);
             * } */
            setItems([newItems]);
        }
    });
    const fetchData = () => {
        setOffset(offset + PER_PAGE);
    }
    const itemByKey = (key: string): any => {
        return items.find((item: any) => item.key === key);
    }
    React.useEffect(() => {
        if (key && !itemByKey(key) && !singleLoading && !loading) {
            console.log('getSingle');
            const single = getSingle({ variables: { key: key } });
            //console.log(single);
        }
    }, [key]);

    if (error) {
        return <div>{JSON.stringify(error, null, 2)}</div>;
    }
    if (loading && items.length === 0) {
        return <div>Loading...</div>;
    }
    return (
        <>
            {items && items.length > 0 && key && itemByKey(key) &&
                <Lightbox
                    mainSrc={props.filePrefix + itemByKey(key).filename}
                    nextSrc={itemByKey(key).next_filename ? (props.filePrefix + itemByKey(key).next_filename) : undefined}
                    prevSrc={itemByKey(key).prev_filename ? props.filePrefix + itemByKey(key).prev_filename : undefined}
                    onCloseRequest={() => {
                        if (items.length === 1 && key) {
                            console.log('reset items');
                            setItems([]);
                            setOffset(0);
                        }
                        history.push(props.filePrefix);
                    }}
                    imageCaption={props.getCaption?.(itemByKey(key))}
                    onMovePrevRequest={() => itemByKey(key).prev ? history.push(props.filePrefix + "single/" + itemByKey(key).prev) : undefined}
                    onMoveNextRequest={() => itemByKey(key).next ? history.push(props.filePrefix + "single/" + itemByKey(key).next) : undefined}
                />
            }
            <InfiniteScroll
                dataLength={items.length}
                next={fetchData}
                hasMore={items.length >= PER_PAGE}
                loader={<h4>Loading...</h4>}
                endMessage={<p></p>}
            >
                {(!called || items.length > 1) &&
                    <Grid>
                        {items.map(({ key, filename_thumb }: { key: string, filename_thumb: string }, index: number) => (
                            <Cell key={key}>
                                <a onClick={() => history.push(props.filePrefix + "single/" + key)}><Thumbnail src={props.filePrefix + filename_thumb} /></a>
                            </Cell>
                        ))}
                    </Grid>
                }
            </InfiniteScroll>
        </>
    )

};

export default Gallery;
